import { CoursesPage } from '@pages/admin/courses/courses.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/courses')({
  component: CoursesPage,
  meta: () => [
    {
      title: 'Admin | Cours',
    },
  ],
});
