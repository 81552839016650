import { TeacherCoursesPage } from '@pages/teacher/courses';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/teacher/courses')({
  component: TeacherCoursesPage,
  meta: () => [
    {
      title: 'Professeur | Cours',
    },
  ],
});
