import dayjs from 'dayjs';
import { Course } from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { useCourse } from '@hooks/services/course.tsx';
import { useLevel } from '@hooks/services/level.tsx';
import { useSubject } from '@hooks/services/subject.tsx';
import {
  IconCalendar,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconDotsVertical,
  IconExternalLink,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import {
  ActionIcon,
  Badge,
  Box,
  Collapse,
  Divider,
  Flex,
  Menu,
  rem,
  Skeleton,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import CourseEditModal from '@components/course-edit-modal/course-edit-modal.tsx';

export type CourseCardProps = {
  course: Course;
};

export const CourseCard = ({ course }: CourseCardProps) => {
  const [opened, { toggle }] = useDisclosure(false);

  const { data, isLoading } = useCourse({
    input: {
      id: course.id,
    },
  });

  const { data: courseLevel, isLoading: isCourseLevelLoading } = useLevel({
    input: {
      id: course.levelId,
    },
  });

  const { data: courseSubject, isLoading: isCourseSubjectLoading } = useSubject({
    input: {
      id: course.subjectId,
    },
  });

  if (isLoading || isCourseLevelLoading || isCourseSubjectLoading) {
    return <Skeleton />;
  }

  const startTime = dayjs(data?.course?.startTime?.toDate());
  const endTime = startTime.add(Number(data?.course?.duration?.seconds), 'second');
  const coursePrice = (data?.course?.price || 0) / 100;

  const openEditModal = (course: Course | undefined) => () => {
    return modals.open({
      title: 'Modifier le cours',
      children: <CourseEditModal opened={true} onClose={() => modals.closeAll()} courseId={course?.id} />,
      size: 'lg',
    })
  }

  return (
    <Box
      w="100%"
      p="xs"
      mih="120px"
      style={{
        border: '1px solid #000',
        borderColor: 'var(--mantine-color-default-border)',
        borderRadius: 4,
      }}
    >
      <Flex direction="column" h="100%">
        <Flex direction="row" h="120px" gap="md">
          <Flex h="100%" justify="center" direction="column">
            <ActionIcon onClick={toggle}>
              {opened ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
          </Flex>
          <Flex direction="column" h="100%" justify="center" miw="140px">
            <Text size="md" fw={600}>
              {startTime.format('DD/MM/YYYY HH:mm')}
            </Text>
            <Text c="dimmed" size="md">
              {endTime.format('DD/MM/YYYY HH:mm')}
            </Text>
          </Flex>
          <Divider orientation="vertical" size="sm" />
          <Flex direction="column" justify="center" gap="md" miw="200px">
            <Text size="md" fw="600">
              {data?.course?.title}
            </Text>
            <Box>
              <Badge color="red">{courseLevel?.level?.name}</Badge>{' '}
              <Badge color="grape">{courseSubject?.subject?.name}</Badge>
            </Box>
          </Flex>
          <Flex direction="row-reverse" justify="center" w="100%">
            <Flex direction="column" justify="center">
              <Box>
                <Badge color="gray">0/10 inscrits</Badge>
              </Box>
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Menu shadow="md">
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  component="a"
                  target="_blank"
                  href={data?.course?.videoLink}
                  leftSection={<IconExternalLink style={{ width: rem(14), height: rem(14) }} />}
                >
                  Accès Visio
                </Menu.Item>
                <Menu.Item
                  disabled={startTime.isBefore(dayjs())}
                  onClick={openEditModal(data?.course)}
                  leftSection={<IconPencil style={{ width: rem(14), height: rem(14) }} />}
                >
                  Modifier
                </Menu.Item>
                <Menu.Item
                  disabled={startTime.isBefore(dayjs())}
                  leftSection={<IconCalendar style={{ width: rem(14), height: rem(14) }} />}
                >
                  Reporter
                </Menu.Item>
                <Menu.Item
                  color="red"
                  disabled={true}
                  leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                >
                  Annuler
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <Flex direction="column" justify="center">
            <ActionIcon>
              <IconChevronRight />
            </ActionIcon>
          </Flex>
        </Flex>
        <Collapse in={opened}>
          <Box
            p="md"
            style={{
              border: '1px solid #000',
              borderColor: 'var(--mantine-color-default-border)',
              borderRadius: 4,
            }}
          >
            <Text size="md" fw="500">
              Description:
            </Text>
            <Text size="sm">{data?.course?.description}</Text>
            <Text size="md" fw="500">
              Liste des inscrits:
            </Text>
            <Text size="sm">Aucun inscrit</Text>
            <Text size="md" fw="500">
              Durée:
            </Text>
            <Text size="sm">
              {dayjs
                .duration(Number(data?.course?.duration?.seconds), 'seconds')
                ?.format('H[h] m[m] s[s]')}
            </Text>
            <Text size="md" fw="500">
              Prix par élève:
            </Text>
            <Text size="sm">
              {coursePrice} {data?.course?.currency}
            </Text>
            <Text size="md" fw="500">
              Prix total:
            </Text>
            <Text size="sm">
              {coursePrice * 0} {data?.course?.currency}
            </Text>
          </Box>
        </Collapse>
      </Flex>
    </Box>
  );
};
