import { SubjectsPage } from '@pages/admin/subjects/subjects.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/subjects')({
  component: SubjectsPage,
  meta: () => [
    {
      title: 'Admin | Matières',
    },
  ],
});
