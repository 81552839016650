import { useCallback, useMemo } from 'react';
import { IconInfoCircle, IconPencil, IconX } from '@tabler/icons-react';
import { MRT_RowData } from 'mantine-react-table';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Flex,
  MantineStyleProp,
  Popover,
  Tooltip,
} from '@mantine/core';
import { RowActionComponentProps } from './types.ts';
import { useDisclosure } from '@mantine/hooks';

export const RowActions = <TData extends MRT_RowData>(props: RowActionComponentProps<TData>) => {
  const { row, onEdit, onDelete } = props;
  const [opened, { close, open }] = useDisclosure(false);

  const handleEdit = useCallback(() => {
    onEdit(row.original.id);
  }, [onEdit, row.original.id]);

  const handleDelete = useCallback(() => {
    onDelete(row.original.id);
    close();
  }, [onDelete, row.original.id, close]);

  const editTooltip = useMemo(
    () => (
      <Tooltip label="Modifier">
        <ActionIcon variant="light" onClick={handleEdit}>
          <IconPencil stroke={1.5} size={18} />
        </ActionIcon>
      </Tooltip>
    ),
    [handleEdit]
  );

  const deleteTooltip = useMemo(
    () => (
      <Popover withArrow shadow="md" opened={opened}>
        <Tooltip label="Supprimer">
          <Popover.Target>
            <ActionIcon variant="light" color="red" onClick={open}>
              <IconX stroke={1.5} size={18} />
            </ActionIcon>
          </Popover.Target>
        </Tooltip>
        <Popover.Dropdown>
          <Flex style={{ width: 300 }} direction="column" gap="md">
            <Alert variant="light" color="red" icon={<IconInfoCircle />}>
              Êtes-vous sûr de vouloir supprimer cet élément ? <br /> Cette action est irréversible.
            </Alert>
            <Flex gap="md" justify="center">
              <Button variant="filled" color="red" onClick={handleDelete} fullWidth>
                Supprimer
              </Button>
            </Flex>
          </Flex>
        </Popover.Dropdown>
      </Popover>
    ),
    [handleDelete, open, opened]
  );

  const boxStyle: MantineStyleProp = useMemo(
    () => ({
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '8px',
    }),
    []
  );

  return (
    <Box style={boxStyle}>
      <Tooltip.Group openDelay={500} closeDelay={100}>
        {editTooltip}
        {deleteTooltip}
      </Tooltip.Group>
    </Box>
  );
};
