import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CourseCreateRequestSchema } from '@api/dtos/course';
import { Duration, Timestamp } from '@bufbuild/protobuf';
import { useCreateCourse } from '@hooks/services/course';
import { useListLevels } from '@hooks/services/level';
import { useListSubjects } from '@hooks/services/subject';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import {
  Button,
  Fieldset,
  Flex,
  LoadingOverlay,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

interface CourseCreateModalProps {
  opened: boolean;
  onClose: () => void;
  teacherId?: string;
}

export const CourseCreateModal = ({ opened, onClose, teacherId }: CourseCreateModalProps) => {
  const defaultValues = {
    title: '',
    description: '',
    maxStudents: 10,
    levelId: '',
    subjectId: '',
    videoLink: '',
    startTime: new Date(),
    duration: 1,
    teacherId: teacherId, // Pre-set with current user's ID
  };

  const mutateCourse = useCreateCourse();

  const { data: levelsData } = useListLevels({
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const [selectedLevelId, setSelectedLevel] = useState<string | undefined>();

  const { data: subjectsData } = useListSubjects({
    input: {
      pageSize: 1000,
      page: 1,
      levelId: selectedLevelId ?? undefined,
    },
  });

  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  const subjectOptions = useMemo(
    () =>
      subjectsData?.subjects?.map((subject) => ({
        value: subject.id,
        label: subject.name,
      })) ?? [],
    [subjectsData?.subjects]
  );

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CourseCreateRequestSchema,
    },
    onSubmit: async ({ value }) => {
      const duration = new Duration({
        seconds: BigInt(value.duration * 60 * 60),
      });
      const startTime = new Timestamp({
        seconds: BigInt(dayjs(value.startTime).unix()),
      });

      mutateCourse.mutate(
        {
          ...value,
          duration,
          startTime,
          teacherId, // Ensure teacher ID is set
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    },
  });

  const { Field, handleSubmit, reset, Subscribe } = form;

  const handleClose = useCallback(() => {
    reset();
    setSelectedLevel(undefined);
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (opened) {
      reset();
    }
  }, [opened, reset]);

  return (
    <div>
      <LoadingOverlay
        visible={mutateCourse.isPending}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void handleSubmit();
        }}
      >
        <Flex direction="column" gap="md">
          <Fieldset legend="Informations du cours" variant="filled">
            <Field
              name="title"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom du cours"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <Textarea
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  placeholder="Description du cours"
                  withAsterisk
                  autosize
                  minRows={3}
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => {
                    handleChange(value as string);
                    setSelectedLevel(value as string);
                    // Clear subject selection when level changes
                    form.setFieldValue('subjectId', '');
                  }}
                  onBlur={handleBlur}
                  label="Niveau"
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  withAsterisk
                  searchable
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="subjectId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Matière"
                  placeholder="Sélectionner une matière"
                  data={subjectOptions}
                  withAsterisk
                  searchable
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="startTime"
              children={({ state, handleChange, handleBlur }) => (
                <DateTimePicker
                  value={state.value}
                  onChange={(value) => handleChange(value ?? new Date())}
                  onBlur={handleBlur}
                  label="Date de début"
                  placeholder="Sélectionner la date"
                  withAsterisk
                  valueFormat="DD/MM/YYYY HH:mm"
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="duration"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(value) => handleChange(value as number)}
                  onBlur={handleBlur}
                  label="Durée (heures)"
                  placeholder="Durée du cours"
                  min={1}
                  max={10}
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="maxStudents"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(value) => handleChange(value as number)}
                  onBlur={handleBlur}
                  label="Nombre d'élèves max"
                  placeholder="Nombre maximum d'élèves"
                  min={1}
                  max={10}
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
            <Field
              name="videoLink"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Lien vidéo"
                  placeholder="Lien de la visioconférence"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(', ') : null}
                />
              )}
            />
          </Fieldset>
          <Flex justify="flex-end" mt="md">
            <Subscribe
              selector={(state) => [state.canSubmit]}
              children={([canSubmit]) => (
                <Button
                  type="submit"
                  disabled={!canSubmit || mutateCourse.isPending}
                  loading={mutateCourse.isPending}
                >
                  Créer
                </Button>
              )}
            />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default CourseCreateModal;
