import {
  GetLevelRequest,
  GetLevelResponse,
  ListLevelsRequest,
  ListLevelsResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/level/v1/level_pb';
import { LevelService } from '@buf/khiman_class-lib.connectrpc_es/proto/level/v1/level_connect';
import {
  createLevel,
  getLevel,
  listLevels,
  updateLevel,
} from '@buf/khiman_class-lib.connectrpc_query-es/proto/level/v1/level-LevelService_connectquery';
import { ConnectError } from '@connectrpc/connect';
import { ConnectQueryKey, useMutation, useQuery } from '@connectrpc/connect-query';
import type {
  UseQueryOptions as TanStackUseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { queryClient } from '@utils/queryclient.ts';
import { notifications } from '@mantine/notifications';
import { useCustomTransport } from '../useCustomTransport';

type ListLevelsInput = Partial<ListLevelsRequest>;

type ListLevelsQueryOptions = Omit<
  TanStackUseQueryOptions<
    ListLevelsResponse,
    ConnectError,
    ListLevelsResponse,
    ConnectQueryKey<ListLevelsRequest>
  >,
  'queryKey' | 'queryFn'
>;

type UseListLevelsProps = {
  input?: ListLevelsInput;
  options?: ListLevelsQueryOptions;
  requiresAuth?: boolean;
};

export const useListLevels = ({
  input = {},
  options = {},
  requiresAuth = true,
}: UseListLevelsProps = {}): UseQueryResult<ListLevelsResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(listLevels, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

type GetLevelInput = Partial<GetLevelRequest>;

type UseLevelProps = {
  input: GetLevelInput;
  requiresAuth?: boolean;
};

export const useLevel = ({
  input,
  requiresAuth = true,
}: UseLevelProps): UseQueryResult<GetLevelResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(getLevel, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!input.id,
  });
};

export const useUpdateLevel = () => {
  const transport = useCustomTransport(true);

  return useMutation(updateLevel, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LevelService.typeName] });
      notifications.show({
        title: 'Niveau mis à jour',
        message: 'Le niveau a été mis à jour avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la mise à jour',
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useCreateLevel = () => {
  const transport = useCustomTransport(true);

  return useMutation(createLevel, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LevelService.typeName] });
      notifications.show({
        title: 'Niveau créé',
        message: 'Le niveau a été créé avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la création',
        message: error.message,
        color: 'red',
      });
    },
  });
};
