import { useUser } from '@hooks/services/user.tsx';
import { Skeleton } from '@mantine/core';

export type TeacherCellProps = {
  teacherId: string;
};

export const TeacherCell = ({ teacherId }: TeacherCellProps) => {
  const { data, isLoading } = useUser({ input: { id: teacherId } });

  if (isLoading) {
    return <Skeleton height={20} />;
  }

  return (
    <span>
      {data?.user?.firstName} {data?.user?.lastName}
    </span>
  );
};
