import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListCoursesResponse } from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { CourseCard } from '@components/course-card/course-card';
import { CourseCreateModal } from '@components/course-create-modal/course-create-modal';
import { useMe } from '@hooks/services/auth';
import { useInfiniteListCourses } from '@hooks/services/course';
import { InfiniteData } from '@tanstack/react-query';
import { Box, Button, Skeleton, Stack, Tabs, Text } from '@mantine/core';
import { modals } from '@mantine/modals';

enum TabCategory {
  FUTURE = 'future',
  PAST = 'past',
}

const ITEMS_PER_PAGE = 5;

export const TeacherCoursesPage = () => {
  const userId = useMe();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string | null>(TabCategory.FUTURE);

  const now = useMemo(() => {
    const timeMS = Date.now();
    return Timestamp.fromDate(new Date(timeMS));
  }, []);

  const {
    data: pastCourses,
    isLoading: isPastCoursesLoading,
    isFetching: isPastFetching,
    fetchNextPage: fetchNextPastPage,
    hasNextPage: hasPastNextPage,
    isFetchingNextPage: isFetchingPastNextPage,
  } = useInfiniteListCourses({
    teacherId: userId,
    before: now,
    pageSize: ITEMS_PER_PAGE,
  });

  const {
    data: futureCourses,
    isLoading: isFutureCoursesLoading,
    isFetching: isFutureFetching,
    fetchNextPage: fetchNextFuturePage,
    hasNextPage: hasFutureNextPage,
    isFetchingNextPage: isFetchingFutureNextPage,
  } = useInfiniteListCourses({
    teacherId: userId,
    after: now,
    pageSize: ITEMS_PER_PAGE,
  });

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      if (activeTab === TabCategory.PAST) {
        if (hasPastNextPage && !isPastFetching && !isFetchingPastNextPage) {
          console.log('Loading more past courses...');
          fetchNextPastPage();
        }
      } else if (activeTab === TabCategory.FUTURE) {
        if (hasFutureNextPage && !isFutureFetching && !isFetchingFutureNextPage) {
          console.log('Loading more future courses...');
          fetchNextFuturePage();
        }
      }
    },
    [
      activeTab,
      hasPastNextPage,
      hasFutureNextPage,
      isPastFetching,
      isFutureFetching,
      isFetchingPastNextPage,
      isFetchingFutureNextPage,
      fetchNextPastPage,
      fetchNextFuturePage,
    ]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: scrollContainerRef.current,
      rootMargin: '200px',
      threshold: 0,
    });

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [handleIntersect]);

  const handleManualLoadMore = () => {
    if (activeTab === TabCategory.PAST && hasPastNextPage && !isPastFetching) {
      fetchNextPastPage();
    } else if (activeTab === TabCategory.FUTURE && hasFutureNextPage && !isFutureFetching) {
      fetchNextFuturePage();
    }
  };

  const openCreateModal = () => {
    modals.open({
      title: 'Créer un nouveau cours',
      children: (
        <CourseCreateModal opened={true} onClose={() => modals.closeAll()} teacherId={userId} />
      ),
      size: 'lg',
    });
  };

  const renderCourses = (courses: InfiniteData<ListCoursesResponse, unknown> | undefined) => {
    if (!courses) return null;

    return courses.pages.map((page, pageIndex) => (
      <React.Fragment key={pageIndex}>
        {page.courses.map((course) => (
          <CourseCard course={course} key={course.id} />
        ))}
      </React.Fragment>
    ));
  };

  return (
    <Tabs defaultValue={TabCategory.FUTURE} onChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value={TabCategory.PAST}>Passés</Tabs.Tab>
        <Tabs.Tab value={TabCategory.FUTURE}>À venir</Tabs.Tab>
      </Tabs.List>

      <Button mt="md" onClick={openCreateModal}>
        Ajouter un cours
      </Button>

      <Box
        ref={scrollContainerRef}
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Tabs.Panel value={TabCategory.PAST}>
          <Skeleton visible={isPastCoursesLoading} height={120} mt="md">
            <Stack mt="md">
              {renderCourses(pastCourses)}
              {(hasPastNextPage || isFetchingPastNextPage) && (
                <div
                  ref={loadMoreRef}
                  style={{
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isFetchingPastNextPage ? (
                    <Text size="sm" color="dimmed">
                      Chargement...
                    </Text>
                  ) : (
                    <Button
                      variant="subtle"
                      onClick={handleManualLoadMore}
                      disabled={!hasPastNextPage || isPastFetching}
                    >
                      Charger plus
                    </Button>
                  )}
                </div>
              )}
            </Stack>
          </Skeleton>
        </Tabs.Panel>

        <Tabs.Panel value={TabCategory.FUTURE}>
          <Skeleton visible={isFutureCoursesLoading} height={120} mt="md">
            <Stack mt="md">
              {renderCourses(futureCourses)}
              {(hasFutureNextPage || isFetchingFutureNextPage) && (
                <div
                  ref={loadMoreRef}
                  style={{
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isFetchingFutureNextPage ? (
                    <Text size="sm" c="dimmed">
                      Chargement...
                    </Text>
                  ) : (
                    <Button
                      variant="subtle"
                      onClick={handleManualLoadMore}
                      disabled={!hasFutureNextPage || isFutureFetching}
                    >
                      Charger plus
                    </Button>
                  )}
                </div>
              )}
            </Stack>
          </Skeleton>
        </Tabs.Panel>
      </Box>
    </Tabs>
  );
};
