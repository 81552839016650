import { QueryClient } from '@tanstack/react-query';
import { hashFn } from "@wagmi/core/query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryKeyHashFn: hashFn,
    }
  }
});
