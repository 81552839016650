import { useCallback, useMemo, useState } from 'react';
import {
  Course,
  ListCoursesResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { LevelCell } from '@components/cells/level-cell.tsx';
import { TeacherCell } from '@components/cells/teacher-cell.tsx';
import { VideolinkCell } from '@components/cells/videolink-cell.tsx';
import { DataTable } from '@components/data-table/data-table';
import { RowActions } from '@components/data-table/row-actions';
import { RowActionProps } from '@components/data-table/types';
import { ConnectError } from '@connectrpc/connect';
import { useListCourses } from '@hooks/services/course.tsx';
import { CourseCreateDrawer } from '@pages/admin/courses/components/course-create-drawer.tsx';
import { IconPlus } from '@tabler/icons-react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Button, NumberFormatter } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CourseEditDrawer } from './components/course-edit-drawer.tsx';

export const CoursesPage = () => {
  const [selectedCourseId, setSelectedCourse] = useState<string | undefined>();
  const [editCourseDrawerOpen, { open: openEditCourseDrawer, close: closeEditCourseDrawer }] =
    useDisclosure(false);
  const [createCourseDrawerOpen, { open: openCreateCourseDrawer, close: closeCreateCourseDrawer }] =
    useDisclosure(false);

  const handleEdit = useCallback(
    (courseId: string) => {
      if (courseId) {
        setSelectedCourse(courseId);
        openEditCourseDrawer();
      }
    },
    [openEditCourseDrawer]
  );

  const customTableActions = (
    <Button leftSection={<IconPlus />} onClick={openCreateCourseDrawer}>
      Ajouter un cours
    </Button>
  );

  const handleDelete = useCallback(async (courseId: string) => {
    // Implement delete functionality here
    console.log('Delete course:', courseId);
  }, []);

  const columns = useMemo<MRT_ColumnDef<Course>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Titre',
      },
      {
        accessorKey: 'levelId',
        header: 'Niveau',
        Cell: ({ cell }) => <LevelCell levelId={cell.getValue<string>()} />,
      },
      {
        enableSorting: false,
        accessorKey: 'videoLink',
        header: 'Lien vidéo',
        Cell: ({ cell }) => <VideolinkCell link={cell.getValue() as string} />,
      },
      {
        accessorKey: 'startTime',
        accessorFn: (course) => course.startTime?.toDate().toLocaleDateString(),
        header: 'Date de début',
      },
      {
        accessorKey: 'duration',
        accessorFn: (course) => {
          return `${Number(course.duration?.seconds) / 60}m`;
        },
        header: 'Durée',
      },
      {
        accessorKey: 'price',
        accessorFn: (course) => (
          <NumberFormatter value={course.price / 100} suffix={course.currency} />
        ),
        header: 'Prix',
      },
      {
        accessorKey: 'teacherId',
        header: 'Enseignant',
        Cell: ({ cell }) => <TeacherCell teacherId={cell.getValue<string>()} />,
      },
    ],
    []
  );

  const queryParams = useMemo(() => ({}), []);

  const customRowActions = useCallback(
    (props: RowActionProps<Course>) => (
      <RowActions<Course> {...props} onEdit={handleEdit} onDelete={handleDelete} />
    ),
    [handleEdit, handleDelete]
  );

  const responseAdapter = useCallback(
    (response: ListCoursesResponse | undefined) => ({
      items: response?.courses ?? [],
      total: response?.total ?? 0,
    }),
    []
  );

  return (
    <>
      <CourseEditDrawer
        opened={editCourseDrawerOpen}
        onClose={closeEditCourseDrawer}
        courseId={selectedCourseId}
      />
      <CourseCreateDrawer opened={createCourseDrawerOpen} onClose={closeCreateCourseDrawer} />
      <DataTable<Course, ListCoursesResponse, ConnectError>
        columns={columns}
        useQueryHook={useListCourses}
        queryParams={queryParams}
        enableCustomRowActions={true}
        enableFilterModes={false}
        customRowActions={customRowActions}
        customActions={customTableActions}
        responseAdapter={responseAdapter}
        errorAdapter={(error) => new Error(error.message)}
      />
    </>
  );
};
