import {
  GetSubjectRequest,
  GetSubjectResponse,
  ListSubjectsRequest,
  ListSubjectsResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/subject/v1/subject_pb';
import { SubjectService } from '@buf/khiman_class-lib.connectrpc_es/proto/subject/v1/subject_connect';
import {
  createSubject,
  getSubject,
  listSubjects,
  updateSubject,
} from '@buf/khiman_class-lib.connectrpc_query-es/proto/subject/v1/subject-SubjectService_connectquery';
import { ConnectError } from '@connectrpc/connect';
import { ConnectQueryKey, useMutation, useQuery } from '@connectrpc/connect-query';
import type {
  UseQueryOptions as TanStackUseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { queryClient } from '@utils/queryclient.ts';
import { notifications } from '@mantine/notifications';
import { useCustomTransport } from '../useCustomTransport';

type ListSubjectsInput = Partial<ListSubjectsRequest>;

type ListSubjectsQueryOptions = Omit<
  TanStackUseQueryOptions<
    ListSubjectsResponse,
    ConnectError,
    ListSubjectsResponse,
    ConnectQueryKey<ListSubjectsRequest>
  >,
  'queryKey' | 'queryFn'
>;

type UseListSubjectsProps = {
  input?: ListSubjectsInput;
  options?: ListSubjectsQueryOptions;
  requiresAuth?: boolean;
};

export const useListSubjects = ({
  input = {},
  options = {},
  requiresAuth = true,
}: UseListSubjectsProps = {}): UseQueryResult<ListSubjectsResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(listSubjects, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

type GetSubjectInput = Partial<GetSubjectRequest>;

type UseSubjectProps = {
  input: GetSubjectInput;
  requiresAuth?: boolean;
};

export const useSubject = ({
  input,
  requiresAuth = true,
}: UseSubjectProps): UseQueryResult<GetSubjectResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(getSubject, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!input.id,
  });
};

export const useUpdateSubject = () => {
  const transport = useCustomTransport(true);

  return useMutation(updateSubject, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SubjectService.typeName] });
      notifications.show({
        title: 'Matière mis à jour',
        message: 'La matière a été mis à jour avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la mise à jour de la matière',
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useCreateSubject = () => {
  const transport = useCustomTransport(true);

  return useMutation(createSubject, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SubjectService.typeName] });
      notifications.show({
        title: 'Matière créée',
        message: 'La matière a été créée avec succès',
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Erreur lors de la création de la matière',
        message: error.message,
        color: 'red',
      });
    },
  });
};
