import {
  CreateUserRequest,
  UpdateUserOperation,
  UserRole,
} from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { validateProtoSchema } from '@utils/zod.ts';
import { z } from 'zod';

export const UpdateOwnUserPasswordRequestSchema = validateProtoSchema<UpdateUserOperation>(
  z
    .object({
      id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
      password: z
        .string({ invalid_type_error: 'Password is required' })
        .min(8, 'Password needs to be at least 8 characters long'),
      confirmPassword: z
        .string({ invalid_type_error: 'Confirm password is required' })
        .min(8, 'Password needs to be at least 8 characters long'),
    })
    .superRefine(({ confirmPassword, password }, ctx) => {
      if (password !== confirmPassword) {
        return ctx.addIssue({
          code: 'custom',
          message: 'Les mots de passe ne correspondent pas',
          path: ['confirmPassword'],
        });
      }
    })
).validateType();

export const UpdateOwnUserRequestSchema = validateProtoSchema<UpdateUserOperation>(
  z.object({
    id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
    firstName: z
      .string({ invalid_type_error: 'First name is required' })
      .min(1, 'First name is required'),
    lastName: z
      .string({ invalid_type_error: 'Last name is required' })
      .min(1, 'Last name is required'),
    email: z.string({ invalid_type_error: 'Email is required' }).email('Invalid email'),
  })
).validateType();

export const UpdateUserRequestSchema = validateProtoSchema<UpdateUserOperation>(
  z.object({
    id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
    firstName: z
      .string({ invalid_type_error: 'First name is required' })
      .min(1, 'First name is required'),
    lastName: z
      .string({ invalid_type_error: 'Last name is required' })
      .min(1, 'Last name is required'),
    email: z.string({ invalid_type_error: 'Email is required' }).email('Invalid email'),
    role: z.nativeEnum(UserRole, { invalid_type_error: 'Role is required' }),
    authorized: z.boolean({ invalid_type_error: 'Authorized is required' }),
  })
).validateType();

export const CreateUserRequestSchema = validateProtoSchema<CreateUserRequest>(
  z.object({
    firstName: z
      .string({ invalid_type_error: 'First name is required' })
      .min(1, 'First name is required'),
    lastName: z
      .string({ invalid_type_error: 'Last name is required' })
      .min(1, 'Last name is required'),
    email: z.string({ invalid_type_error: 'Email is required' }).email('Invalid email'),
    role: z.nativeEnum(UserRole, { invalid_type_error: 'Role is required' }),
    authorized: z.boolean({ invalid_type_error: 'Authorized is required' }),
    password: z
      .string({ invalid_type_error: 'Password is required' })
      .min(8, 'Password is required'),
  })
).validateType();
