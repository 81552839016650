import {
  CreateLevelRequest,
  UpdateLevelOperation,
} from '@buf/khiman_class-lib.bufbuild_es/proto/level/v1/level_pb';
import { validateProtoSchema } from '@utils/zod.ts';
import { z } from 'zod';

export const UpdateLevelRequestSchema = validateProtoSchema<UpdateLevelOperation>(
  z.object({
    id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
    name: z.string({ invalid_type_error: 'Name is required' }).min(1, 'Name is required'),
    description: z
      .string({ invalid_type_error: 'Description is required' })
      .min(1, 'Description is required'),
  })
).validateType();

export const CreateLevelRequestSchema = validateProtoSchema<CreateLevelRequest>(
  z.object({
    name: z.string({ invalid_type_error: 'Name is required' }).min(1, 'Name is required'),
    description: z
      .string({ invalid_type_error: 'Description is required' })
      .min(1, 'Description is required'),
  })
).validateType();
