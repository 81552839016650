import { ForwardRefExoticComponent, RefAttributes, useCallback } from 'react';
import {
  Icon,
  IconBrandGoogle,
  IconBrandTeams,
  IconBrandZoom,
  IconExternalLink,
  IconProps,
} from '@tabler/icons-react';
import { ActionIcon } from '@mantine/core';

export type VideoLinkCellProps = {
  link?: string;
};

type VideoLinkEntry = {
  name: string;
  matchingSlugs: string[];
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
};

const VideoLinkDict: VideoLinkEntry[] = [
  {
    name: 'Autre',
    matchingSlugs: ['meet.google.com'],
    icon: IconBrandGoogle,
  },
  {
    name: 'Teams',
    matchingSlugs: ['teams.microsoft.com'],
    icon: IconBrandTeams,
  },
  {
    name: 'Zoom',
    matchingSlugs: ['zoom.com', 'zoom.us'],
    icon: IconBrandZoom,
  },
  {
    name: 'Jitsi Meet',
    matchingSlugs: ['jitsi'],
    icon: IconExternalLink,
  },
  {
    name: 'Cisco Webex',
    matchingSlugs: ['cisco', 'webex'],
    icon: IconExternalLink,
  },
];

export const VideolinkCell = ({ link = '' }: VideoLinkCellProps) => {
  const parseLink = useCallback(
    (link: string) => {
      const matchingEntry = VideoLinkDict.find((entry) =>
        entry.matchingSlugs.some((slug) => link.includes(slug))
      );

      return matchingEntry ?? {
        name: 'Autre',
        icon: IconExternalLink,
      }
    },
    []
  );

  const parsedLink = parseLink(link)

  return (
    <ActionIcon
      component="a"
      href={link}
      disabled={!link}
      aria-label={parsedLink.name}
      target="_blank"
    >
      <parsedLink.icon size={16}/>
    </ActionIcon>
  )
};
