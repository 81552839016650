import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { CourseCreateRequestSchema } from '@api/dtos/course.ts';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { Duration, Timestamp } from '@bufbuild/protobuf';
import { useCreateCourse } from '@hooks/services/course.tsx';
import { useListLevels } from '@hooks/services/level.tsx';
import { useListSubjects } from '@hooks/services/subject';
import { useListUsers } from '@hooks/services/user.tsx';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import {
  Button,
  Drawer,
  Fieldset,
  Flex,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';

interface SubjectDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const CourseCreateDrawer = (props: SubjectDrawerProps) => {
  const { opened, onClose } = props;
  const [selectedLevelId, setSelectedLevel] = useState<string | undefined>();
  const mutateCourse = useCreateCourse();
  const defaultValues = {
    title: '',
    description: '',
    maxStudents: 10,
    levelId: '',
    subjectId: '',
    videoLink: '',
    startTime: new Date(),
    duration: 1,
    teacherId: '',
  };

  const { data: levelsData } = useListLevels({
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const { data: subjectsData } = useListSubjects({
    input: {
      pageSize: 1000,
      page: 1,
      levelId: selectedLevelId ?? undefined,
    },
  });

  const { data: teachersData } = useListUsers({
    input: {
      pageSize: 1000,
      page: 1,
      role: UserRole.TEACHER,
    },
  });

  // Here we memoize the teachers options to avoid re-rendering the component since this is unlikely to change often
  const teacherOptions = useMemo(
    () =>
      teachersData?.users?.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) ?? [],
    [teachersData?.users]
  );

  // Here we memoize the levels options to avoid re-rendering the component since this is unlikely to change often
  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  // Here we memoize the subjects options to avoid re-rendering the component since this is unlikely to change often
  const subjectOptions = useMemo(
    () =>
      subjectsData?.subjects?.map((subject) => ({
        value: subject.id,
        label: subject.name,
      })) ?? [],
    [subjectsData?.subjects]
  );

  const { Field, handleSubmit, reset, Subscribe } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CourseCreateRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const duration = new Duration({
        seconds: BigInt(value.duration * 60 * 60),
      });
      const startTime = new Timestamp({
        seconds: BigInt(dayjs(value.startTime).unix()),
      });

      mutateCourse.mutate(
        {
          ...value,
          duration,
          startTime,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    },
    onSubmitInvalid: (errors) => {
      console.log('Errors:', errors);
    },
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Niveau" variant="filled" w="100%">
            <Field
              name="title"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom"
                  autoComplete="off"
                  withAsterisk
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <Textarea
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  withAsterisk
                  autosize
                  placeholder="Description"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => {
                    handleChange(value as string);
                    setSelectedLevel(value as string);
                  }}
                  onBlur={handleBlur}
                  label="Niveau"
                  withAsterisk
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="subjectId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Matière"
                  withAsterisk
                  placeholder="Sélectionner une matière"
                  data={subjectOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="teacherId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Professeur"
                  withAsterisk
                  placeholder="Sélectionner un professeur"
                  data={teacherOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
            <Field
              name="maxStudents"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value}
                  onChange={(e) => handleChange(e as number)}
                  onBlur={handleBlur}
                  min={1}
                  max={10}
                  withAsterisk
                  label="Nombre d'élèves"
                  placeholder="Nombre d'élèves"
                  autoComplete="off"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="videoLink"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Lien vidéo"
                  withAsterisk
                  placeholder="Lien vidéo"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="startTime"
              children={({ state, handleChange, handleBlur }) => (
                <DateTimePicker
                  value={state.value}
                  onChange={(value: DateValue) => {
                    handleChange(value ?? new Date());
                  }}
                  onBlur={handleBlur}
                  label="Date de début"
                  withAsterisk
                  placeholder="Date de début"
                  valueFormat="DD/MM/YYYY HH:mm"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="duration"
              children={({ state, handleChange, handleBlur }) => (
                <NumberInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e as number)}
                  onBlur={handleBlur}
                  min={1}
                  max={10}
                  withAsterisk
                  label="Durée (en heures)"
                  placeholder="Durée (en heures)"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.errorMap]}
            children={([errorMap]) =>
              errorMap.onSubmit ? (
                <div>
                  <em>There was an error on the form: {errorMap.onSubmit?.toString()}</em>
                </div>
              ) : null
            }
          />
          <Subscribe
            selector={(state) => [state.isDirty]}
            children={([isDirty]) => (
              <Button
                disabled={!isDirty || mutateCourse.isPending}
                loading={mutateCourse.isPending}
                type="submit"
              >
                Créer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
