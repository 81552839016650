import { z } from 'zod';

export const CourseCreateRequestSchema = z.object({
  title: z.string({ invalid_type_error: 'Title is required' }).min(1, 'Title is required'),
  description: z
    .string({ invalid_type_error: 'Description is required' })
    .min(1, 'Description is required'),
  maxStudents: z
    .number({ invalid_type_error: 'Max students is required' })
    .min(1, 'Max students is required'),
  levelId: z.string({ invalid_type_error: 'LevelId is required' }).min(1, 'LevelId is required'),
  subjectId: z
    .string({ invalid_type_error: 'SubjectId is required' })
    .min(1, 'SubjectId is required'),
  videoLink: z
    .string({ invalid_type_error: 'Video link is required' })
    .url({ message: 'Invalid URL' }),
  startTime: z.date({ invalid_type_error: 'Start time is required' }),
  duration: z.number({ invalid_type_error: 'Duration is required' }).min(1, 'Duration is required'),
  teacherId: z
    .string({ invalid_type_error: 'TeacherId is required' })
    .min(1, 'TeacherId is required'),
});

export const CourseUpdateRequestSchema = z.object({
  id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
  title: z.string({ invalid_type_error: 'Title is required' }).min(1, 'Title is required'),
  description: z
    .string({ invalid_type_error: 'Description is required' })
    .min(1, 'Description is required'),
  maxStudents: z
    .number({ invalid_type_error: 'Max students is required' })
    .min(1, 'Max students is required'),
  levelId: z.string({ invalid_type_error: 'LevelId is required' }).min(1, 'LevelId is required'),
  subjectId: z
    .string({ invalid_type_error: 'SubjectId is required' })
    .min(1, 'SubjectId is required'),
  duration: z.number({ invalid_type_error: 'Duration is required' }).min(1, 'Duration is required'),
  startTime: z.date({ invalid_type_error: 'Start time is required' }),
  videoLink: z
    .string({ invalid_type_error: 'Video link is required' })
    .url({ message: 'Invalid URL' }),
  teacherId: z
    .string({ invalid_type_error: 'TeacherId is required' })
    .min(1, 'TeacherId is required'),
});
