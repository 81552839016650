import React, { ReactNode } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import {
  MRT_Cell,
  MRT_CellValue,
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowData,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_Updater,
} from 'mantine-react-table';

export interface DataTableState {
  columnFilters: MRT_ColumnFiltersState;
  columnFilterFns: MRT_ColumnFilterFnsState;
  globalFilter: string;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  setColumnFilters: (value: MRT_Updater<MRT_ColumnFiltersState>) => void;
  setColumnFilterFns: (value: MRT_Updater<MRT_ColumnFilterFnsState>) => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (value: MRT_Updater<MRT_SortingState>) => void;
  setPagination: (value: MRT_Updater<MRT_PaginationState>) => void;
}

export enum OrderByParam {
  ASC = 'asc',
  DESC = 'desc',
}

export interface DataTableQueryParams {
  input?: {
    pageSize?: number;
    page?: number;
    sortBy?: string,
    orderBy?: OrderByParam;
    [key: string]: unknown;
  };

  [key: string]: unknown;
}

export interface RowActionProps<TData extends MRT_RowData> {
  cell: MRT_Cell<TData, MRT_CellValue>;
  renderedRowIndex?: number;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export interface RowActionComponentProps<TData extends MRT_RowData> extends RowActionProps<TData> {
  onEdit: (elementID: string) => void;
  onDelete: (elementID: string) => void;
}

export interface DataTableProps<TData extends MRT_RowData, TResponse = unknown, TError = unknown> {
  columns: MRT_ColumnDef<TData>[];
  useQueryHook: (params: DataTableQueryParams) => UseQueryResult<TResponse, TError>;
  queryParams?: Omit<DataTableQueryParams, 'input.pageSize' | 'input.page'>;
  customActions?: React.ReactNode;
  enableCustomRowActions?: boolean;
  customRowActions?: (props: RowActionProps<TData>) => ReactNode;
  enableFilterModes?: boolean;
  responseAdapter: (response: TResponse | undefined) => {
    items: TData[];
    total: number;
  };
  errorAdapter?: (error: TError) => Error;
}
