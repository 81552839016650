import {
  GetUserRequest,
  GetUserResponse,
  ListUsersRequest,
  ListUsersResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { UserService } from '@buf/khiman_class-lib.connectrpc_es/proto/user/v1/user_connect';
import {
  createUser,
  deleteUser,
  getUser,
  listUsers,
  updateUser,
} from '@buf/khiman_class-lib.connectrpc_query-es/proto/user/v1/user-UserService_connectquery';
import { ConnectError } from '@connectrpc/connect';
import { ConnectQueryKey, useMutation, useQuery } from '@connectrpc/connect-query';
import type {
  UseQueryOptions as TanStackUseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { queryClient } from '@utils/queryclient.ts';
import { notifications } from '@mantine/notifications';
import { useCustomTransport } from '../useCustomTransport';

type ListUsersInput = Partial<ListUsersRequest>;

type ListUsersQueryOptions = Omit<
  TanStackUseQueryOptions<
    ListUsersResponse,
    ConnectError,
    ListUsersResponse,
    ConnectQueryKey<ListUsersRequest>
  >,
  'queryKey' | 'queryFn'
>;

type UseListUsersProps = {
  input?: ListUsersInput;
  options?: ListUsersQueryOptions;
  requiresAuth?: boolean;
};

export const useListUsers = ({
  input = {},
  options = {},
  requiresAuth = true,
}: UseListUsersProps = {}): UseQueryResult<ListUsersResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(listUsers, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

type GetUserInput = Partial<GetUserRequest>;

type UseUserProps = {
  input: GetUserInput;
  requiresAuth?: boolean;
};

export const useUser = ({
  input,
  requiresAuth = true,
}: UseUserProps): UseQueryResult<GetUserResponse, ConnectError> => {
  const transport = useCustomTransport(requiresAuth);

  return useQuery(getUser, input, {
    transport,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!input.id,
  });
};

export const useUpdateUser = () => {
  const transport = useCustomTransport(true);

  return useMutation(updateUser, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UserService.typeName] });
      notifications.show({
        title: 'Utilisateur mis à jour',
        message: "L'utilisateur a été mis à jour avec succès",
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: "Erreur lors de la mise à jour de l'utilisateur",
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useCreateUser = () => {
  const transport = useCustomTransport(true);

  return useMutation(createUser, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UserService.typeName] });
      notifications.show({
        title: 'Utilisateur créé',
        message: "L'utilisateur a été créé avec succès",
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: "Erreur lors de la création de l'utilisateur",
        message: error.message,
        color: 'red',
      });
    },
  });
};

export const useDeleteUser = () => {
  const transport = useCustomTransport(true);

  return useMutation(deleteUser, {
    transport,
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UserService.typeName] });
      notifications.show({
        title: 'Utilisateur supprimé',
        message: "L'utilisateur a été supprimé avec succès",
        color: 'teal',
      });
    },
    onError: (error) => {
      notifications.show({
        title: "Erreur lors de la suppression de l'utilisateur",
        message: error.message,
        color: 'red',
      });
    },
  });
};
